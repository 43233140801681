export type IAPI = [
  name: string,
  url: string | ((...args: any[]) => string),
  options?: {
    [key: string]: number | string
  },
]

const api: IAPI[] = [
  ['siginin', 'POST /signin'],
  ['book', 'POST /search'],
  ['meta', 'GET /meta/:url'],
]
export default api
