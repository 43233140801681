import { defineAsyncComponent } from 'vue'
import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'
import Not from '../views/notfound'
import { HomeOutlined, UserOutlined } from '@ant-design/icons-vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: defineAsyncComponent(() => import('../views/home')),
  },
  {
    path: '/object/:id',
    name: 'object',
    component: defineAsyncComponent(() => import('../views/object')),
  },
  { path: '/:pathMatch(.*)*', name: 'NotFound', component: Not },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
