type ISetting = {
  theme: 'night' | 'dark'
  fontSize: number
}

export type State = {
  accessToken: string
  setting: ISetting
}

export const state: State = {
  accessToken: '',
  setting: {
    theme: 'night',
    fontSize: 1.125,
  },
}
